// ** React Imports
import { useEffect, useState } from 'react'

// ** Third Party Components
import { NavLink } from 'react-router-dom'

// icons
import { getDataByLanguage } from '@/utils/helpers'
import { Badge } from 'reactstrap'
import {
	Activity,
	Box,
	Briefcase,
	Calendar,
	ChevronDown,
	ChevronUp,
	FileText,
	List,
	LogOut,
	Plus,
	Share2,
	Table,
	User,
	X,
} from 'react-feather'
import useAuthStore from '@/stores/auth-store'
import useCompaniesStore from '@/stores/companies-store'
import ActiveCompanySelector from './ActiveCompanySelector'
import usePermissionsStore, { Permissions } from '@/stores/permissions-store'
import ReferralProgramCard from './ReferralProgramCard'
import classNames from 'classnames'
import logoWasabilImg from '../logo-wasabil.webp'
import './style.scss'
import useLayoutStore from '@/stores/layout-store'
import useRouteStore from '@/stores/route-store'

export default function Sidebar() {
	const locale = useAuthStore((state) => state.locale)
	const activeCompany = useCompaniesStore((state) => state.activeCompany)
	const userCan = usePermissionsStore((state) => state.userCan)
	const [sidebarOpen, setSidebarOpen] = useLayoutStore((state) => [state.sidebarOpen, state.setSidebarOpen])
	const close = () => setSidebarOpen(false)
	const route = useRouteStore((state) => state.route)

	const [purchaseExpanded, setPurchaseExpanded] = useState(false)
	const [saleExpanded, setSaleExpanded] = useState(false)

	const saleDisabled = activeCompany?.issuer_sale_status == 'disabled'
	const purchaseDisabled = activeCompany?.issuer_purchase_status == 'disabled'
	const items = []

	if (activeCompany) {
		items.push(
			{
				title: { es: 'Inicio', en: 'Dashboard' },
				icon: Activity,
				navLink: { to: '/dashboard' },
			},
			{
				divider: true,
			},
			{
				header: 'EMISIÓN DE DOCUMENTOS',
			},
			{
				subheader: 'FACTURA DE COMPRA',
				expanded: purchaseExpanded,
				onClick: () => {
					setPurchaseExpanded(!purchaseExpanded)
				},
			},

			userCan(Permissions.ISSUE_DOCUMENTS) &&
				purchaseExpanded && {
					title: { es: 'Emisión Manual', en: 'Manual Issue' },
					icon: Plus,
					navLink: { to: '/issue' },
					disabled: purchaseDisabled,
				},

			userCan(Permissions.ISSUE_DOCUMENTS) &&
				purchaseExpanded && {
					title: { es: 'Emisión Masiva', en: 'Bulk Issue' },
					icon: Table,
					navLink: { to: '/issue/bulk' },
					disabled: purchaseDisabled,
				},

			userCan(Permissions.EDIT_PROGRAMMED_DOCUMENTS) &&
				purchaseExpanded && {
					title: { es: 'Emisión programada', en: 'Scheduled Invoices' },
					icon: Calendar,
					navLink: { to: '/scheduleds' },
					disabled: purchaseDisabled,
				},

			userCan(Permissions.EDIT_INTEGRATIONS) &&
				purchaseExpanded && {
					title: { es: 'Emisión por Integraciones', en: 'Integrations' },
					icon: Share2,
					navLink: { to: '/integrations' },
					disabled: purchaseDisabled,
				},

			purchaseExpanded && {
				title: { es: 'Documentos Emitidos', en: 'Invoices' },
				icon: FileText,
				navLink: { to: '/documents' },
				disabled: purchaseDisabled,
			},
			{
				divider: true,
				withPadding: true,
			},
			{
				subheader: 'FACTURA DE VENTA',
				expanded: saleExpanded,
				onClick: () => setSaleExpanded(!saleExpanded),
			},

			userCan(Permissions.ISSUE_DOCUMENTS) &&
				saleExpanded && {
					title: { es: 'Emisión Manual', en: 'Manual Issue' },
					icon: Plus,
					navLink: { to: '/sale-invoice/issue' },
					disabled: saleDisabled,
				},

			userCan(Permissions.EDIT_PROGRAMMED_DOCUMENTS) &&
				saleExpanded && {
					title: { es: 'Emisión programada', en: 'Scheduled Invoices' },
					icon: Calendar,
					navLink: { to: '/sale-invoice/scheduleds' },
					disabled: saleDisabled,
				},
			activeCompany &&
				saleExpanded && {
					title: { es: 'Documentos Emitidos', en: 'Invoices' },
					icon: FileText,
					navLink: { to: '/sale-invoice/documents' },
					disabled: saleDisabled,
				},
			{
				divider: true,
			}
		)
	}

	items.push(
		{
			header: 'CONFIGURACIÓN',
		},
		activeCompany && {
			title: { es: 'Listado de Proveedores', en: 'Suppliers' },
			icon: List,
			navLink: { to: '/suppliers' },
		},
		activeCompany && {
			title: { es: 'Listado de Clientes', en: 'Clients' },
			icon: List,
			navLink: { to: '/clients' },
		},
		{
			title: { es: 'Mi empresa', en: 'My company' },
			icon: Briefcase,
			navLink: { to: '/companies', exact: false },
		},
		userCan(Permissions.COMPANY_ADMIN) && {
			title: { es: 'Plan de Suscripción', en: 'My plan' },
			icon: Box,
			navLink: { to: '/plan' },
		},
		activeCompany && {
			custom: (key) => (
				<div style={{ padding: '24px' }} key={key} onClick={close}>
					<ReferralProgramCard />
				</div>
			),
		},
		{
			title: { es: 'Mi cuenta', en: 'Mi cuenta' },
			icon: User,
			navLink: { to: '/account' },
		},
		{
			title: { es: 'Cerrar sesión', en: 'Logout' },
			icon: LogOut,
			onClick: () => useAuthStore.getState().logout(),
			navLink: { isActive: () => false },
		}
	)

	useEffect(() => {
		close()
	}, [route])

	return (
		<div className={classNames(`app-sidebar`, { '--open': sidebarOpen })}>
			<div className="__drawer main-menu menu-light">
				<div className="d-flex align-items-center px-1 py-50">
					<NavLink to="/dashboard" className="__logo" onClick={close}>
						<img src={logoWasabilImg} alt="Wasabil" />
					</NavLink>
					<div className="spacer"></div>
					<X size={30} color="#777" onClick={() => setSidebarOpen(false)} className="__close-x" />
				</div>
				<ActiveCompanySelector />

				<ul className="navigation navigation-main">
					{items.filter(Boolean).map((item, i) => {
						if (item.divider) {
							return (
								<div className={classNames({ 'px-2': item.withPadding })} key={i}>
									<hr className="w-100" />
								</div>
							)
						} else if (item.header) {
							return (
								<li className="navigation-header" key={i}>
									<span>{item.header}</span>
								</li>
							)
						} else if (item.subheader) {
							return (
								<li
									className={classNames(`navigation-subheader`, {
										'navigation-subheader--expanded': item.expanded,
									})}
									key={i}
									onClick={() => item.onClick?.()}
								>
									<span className="d-flex align-items-center">
										{item.subheader}
										<div className="spacer"></div>
										{item.expanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
									</span>
								</li>
							)
						} else if (item.custom) {
							return item.custom(i)
						} else {
							return (
								<li
									key={i}
									className={classNames({
										'nav-item': !item.children,
										disabled: item.disabled,
										active: false, // item.navLink == window.location.pathname,
									})}
								>
									<NavLink
										className="d-flex align-items-center"
										onClick={() => {
											close()
											item.onClick?.()
										}}
										{...{ exact: true, to: 0, ...item.navLink }}
									>
										<item.icon size={16} className="mr-1" />

										<span className="menu-item text-truncate">
											{getDataByLanguage(locale, item.title)}
										</span>

										{!!item.badge && !!item.badgeText && (
											<Badge className="ml-auto mr-1" color={item.badge} pill>
												{item.badgeText}
											</Badge>
										)}
									</NavLink>
								</li>
							)
						}
					})}
				</ul>
			</div>
			<div className="__overlay" onClick={() => setSidebarOpen(false)}></div>
		</div>
	)
}

